<template>
  <Tippy :content="item.disabled">
    <button
      ref="reference"
      :class="[
        'flex w-full min-w-fit items-center transition duration-500 ease-in-out',
        size === 'sm' ? 'p-2 text-xs' : 'p-2.5 text-[13px]',
        item.disabled ? 'cursor-not-allowed opacity-50' : '',
        (item.key && item.key === activeKey) || active
          ? 'bg-cyan-700 bg-opacity-10 font-medium text-cyan-800'
          : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700',
        item.children?.length
          ? 'group-hover/drop-down-item:bg-gray-100 group-hover/drop-down-item:text-gray-700'
          : '',
        isChild
          ? ''
          : 'group-first/drop-down-item:rounded-t-lg group-last/drop-down-item:rounded-b-lg'
      ]"
      type="button"
      :disabled="!!item.disabled"
    >
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center">
          <TwSpinner
            v-if="item.loading"
            :class="[size === 'sm' ? 'mr-3 h-3.5 w-3.5' : 'mr-2 h-5 w-5']"
          />
          <Icon
            v-else-if="item.icon"
            :name="item.icon"
            :class="[size === 'sm' ? 'mr-3 h-3.5 w-3.5' : 'mr-4 h-4 w-4']"
          />
          <img
            v-else-if="item.image"
            :src="item.image"
            :class="[size === 'sm' ? 'mr-3 h-3.5 w-3.5' : 'mr-4 h-4 w-4']"
          />
          <span class="max-w-120 truncate first-letter:uppercase">
            {{ item.title }}
          </span>
        </div>
        <Icon
          v-if="item.altIcon"
          :name="item.altIcon"
          :class="[size === 'sm' ? 'mr-3 h-3.5 w-3.5' : 'mr-4 h-4 w-4']"
        />
        <Icon
          v-if="item.children?.length"
          name="chevron"
          class="h-3 w-3 -rotate-90"
        />
        <Icon v-if="item.active" name="check" class="h-3 w-3" />
      </div>
    </button>
    <div
      v-if="item.children?.length"
      ref="floating"
      :class="[
        'hide-scrollbar pointer-events-none z-50 max-h-[calc(100vh-3rem)] overflow-y-auto rounded-lg bg-white opacity-0 shadow-lg transition-all group-hover/drop-down-item:pointer-events-auto group-hover/drop-down-item:opacity-100',
        size === 'sm' ? 'min-w-36' : 'min-w-48'
      ]"
      :="{ style }"
      @click.stop.prevent="emit('close')"
    >
      <template
        v-for="(child, index) in item.children ?? []"
        :key="child.key ?? child.title"
      >
        <NuxtLink v-if="child.to" :to="child.to" @click="emit('close')">
          <DropDownItem :="{ activeKey, item: child, isChild: true, size }" />
        </NuxtLink>
        <DropDownItem
          v-else
          :="{ activeKey, isChild: true, item: child, size }"
          @click="child.handler?.(child, index)"
        />
      </template>
    </div>
  </Tippy>
</template>

<script setup lang="ts">
// defines
defineProps<{
  active?: boolean
  activeKey?: Primitive
  item: NavItem
  size?: 'sm' | 'md'
  isChild?: boolean
}>()
const emit = defineEmits(['close'])

const { reference, floating, style } = useFloating('right-start', 'absolute')
</script>
