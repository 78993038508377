<template>
  <Menu as="div" class="relative flex flex-col items-end">
    <MenuButton
      v-slot="{ open }: { open: boolean }"
      class="flex w-full items-center focus:outline-none"
      aria-label="drop down button"
    >
      <div ref="reference" class="w-full">
        <slot :="{ open }"></slot>
      </div>
    </MenuButton>
    <Teleport to="body">
      <div
        ref="floating"
        class="z-50"
        :="{
          style: {
            ...style,
            width: matchReferenceWidth
              ? `${reference?.clientWidth}px`
              : undefined
          }
        }"
      >
        <Transition name="drop-down" mode="out-in">
          <MenuItems class="focus:outline-none">
            <div
              :class="[
                'z-20 rounded-lg bg-white shadow-lg',
                size === 'sm' ? 'min-w-36' : 'min-w-48'
              ]"
              @click.stop.prevent=""
            >
              <MenuItem
                v-for="(item, index) in items"
                :key="item.key ?? item.title"
                v-slot="{ active, close }"
                class="group/drop-down-item"
              >
                <NuxtLink v-if="item.to" :to="item.to" @click="close()">
                  <DropDownItem
                    :="{ active, activeKey, item, size }"
                    @close="close()"
                  />
                </NuxtLink>
                <DropDownItem
                  v-else
                  :="{ activeKey, item, size }"
                  @click="item.handler?.(item, index)"
                  @close="close()"
                />
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </div>
    </Teleport>
  </Menu>
</template>

<script setup lang="ts">
import { Placement } from '@floating-ui/dom'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

export type DropDownProps = {
  activeKey?: Primitive
  items: NavItem[]
  placement?: Placement
  size?: 'sm' | 'md'
  matchReferenceWidth?: boolean
}

// defines
const props = defineProps<DropDownProps>()

// data
const { reference, floating, style } = useFloating(
  props.placement || 'bottom-end'
)
</script>
